import { put, call, takeLatest, select, takeEvery } from 'redux-saga/effects';
import {
    WASHOUT_ASSOC_SEARCH_START,
    WASHOUT_ASSOC_SEARCH_COMPLETE,
    WASHOUT_ASSOC_SEARCH_ERROR,
    ADJUSTMENT_LIST_WASHOUT,
    ALL_WASHOUT_START,
    ALL_WASHOUT_COMPLETE,
    COMPLETE_ASSOCIATE_LIST_START,
    COMPLETE_ASSOCIATE_LIST_COMPLETE
} from '../../consts/actionTypes';
import { isLoadingAction, snackBarAction } from '../actions/utilitiesAction';
import { apiCall } from '../api';
import {
    WASHOUT,
    PAYPLAN_SENIOR_PRODUCT_SPECIALIST,
    PAYPLAN_BUSINESS_MENAGER,
    PAYPLAN_PRODUCT_SPECIALIST,
    ALLWASHOUTS,
    WASHOUT_TM,
    COMPLETE_ASSOCIATE_LIST,
} from '../../consts/urls';
import { token, listResponseSelector } from '../selectors';

export function* list({ dms, date, typeAssociate }) {
    try {
        const termListReducer = yield select(listResponseSelector, 'termReducer');
        const responseToken = yield select(token);

        const month = date.slice(0, 3);

        const year = date.slice(3, 5);

        let results,
            resultsNew,
            resultsUsed,
            adjustment = [];

        let isTM = false;

        let payPlanInTerm = [];
        const searchIndex = termListReducer.findIndex(
            (term) => term.MONTH === month && term.YEAR === year
        );

        if (searchIndex > -1) {
            payPlanInTerm = termListReducer[searchIndex].ASSOCIATES.filter(
                (obj) => obj.associate == dms
            );
        }
        let payplan = '';
        if (payPlanInTerm.length > 0) {
            payplan = payPlanInTerm[0].payplan;
        } else {
            if (typeAssociate === '0') {
                payplan = PAYPLAN_PRODUCT_SPECIALIST;
            } else {
                payplan = PAYPLAN_BUSINESS_MENAGER;
            }
        }

        if (typeAssociate !== '2') {
            resultsNew = yield call(
                apiCall,
                `${WASHOUT}/${dms}/${date}/${payplan}/NEW`,
                null,
                responseToken,
                'GET'
            );
            resultsUsed = yield call(
                apiCall,
                `${WASHOUT}/${dms}/${date}/${payplan}/USED`,
                null,
                responseToken,
                'GET'
            );

            results = yield call(
                apiCall,
                `${WASHOUT}/${dms}/${date}/${payplan}`,
                null,
                responseToken,
                'GET'
            );

            adjustment = results.data.adjustments;
        } else {
            results = yield call(
                apiCall,
                `${WASHOUT_TM}/${dms}/${date}/${payplan}`,
                null,
                responseToken,
                'GET'
            );

            isTM = true;

            adjustment = results.data.adjustments;
        }

        yield put({ type: WASHOUT_ASSOC_SEARCH_COMPLETE, results, resultsNew, resultsUsed, isTM });
        yield put({ type: ADJUSTMENT_LIST_WASHOUT, adjustment, isTM, totals: results.data.totals });

        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        if (error.response) {
            const errorResponse = error.response;
            yield put({ type: WASHOUT_ASSOC_SEARCH_ERROR, errorResponse });
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'error Load List: ' + errorResponse.data.message,
                    type: 'error',
                })
            );
        }
        console.log(error);
    }
    yield put(isLoadingAction(false));
}

export function* allList({ payload }) {
    try {
        const responseToken = yield select(token);
        const listAssociate = yield select(listResponseSelector, 'associateReducer');
        const adjustmentConf = yield select((state) => state.adjustmentReducer.adjustmentTable);

        const resultsNew = yield call(
            apiCall,
            `${ALLWASHOUTS}/${payload.month}${payload.year}/NEW`,
            null,
            responseToken,
            'GET'
        );
        const resultsUsed = yield call(
            apiCall,
            `${ALLWASHOUTS}/${payload.month}${payload.year}/USED`,
            null,
            responseToken,
            'GET'
        );

        const results = yield call(
            apiCall,
            `${ALLWASHOUTS}/${payload.month}${payload.year}`,
            null,
            responseToken,
            'GET'
        );

        yield put({
            type: ALL_WASHOUT_COMPLETE,
            results,
            resultsNew,
            resultsUsed,
            listAssociate,
            adjustmentConf,
        });
        yield put(
            snackBarAction({
                open: true,
                msg: results.data.message,
                type: 'success',
            })
        );
    } catch (error) {
        if (error.response) {
            const errorResponse = error.response;
            yield put({ type: WASHOUT_ASSOC_SEARCH_ERROR, errorResponse });
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'error Load List: ' + errorResponse.data.message,
                    type: 'error',
                })
            );
        }
        console.log(error);
    }
    yield put(isLoadingAction(false));
}

function* completeAssociateList({ payload }) {
    try {
        const responseToken = yield select(token);
        const response = yield call(
            apiCall,
            `${COMPLETE_ASSOCIATE_LIST}${payload?.date}/${payload?.typeAssociate}`,
            null,
            responseToken,
            'GET'
        );

        yield put({
            type: COMPLETE_ASSOCIATE_LIST_COMPLETE,
            result: {
                data: response?.data,
                typeAssociate: payload?.typeAssociate,
            },
        });
        yield put(
            snackBarAction({
                open: true,
                msg: response?.data?.message,
                // msg: 'success',
                type: 'success',
            })
        );
    } catch (error) {
        if (error.response) {
            const errorResponse = error.response;
            yield put({ type: WASHOUT_ASSOC_SEARCH_ERROR, errorResponse });
            yield put(
                snackBarAction({
                    open: true,
                    msg: 'error Load List: ' + errorResponse.data.message,
                    type: 'error',
                })
            );
        }
        console.log(error);
    }
    yield put(isLoadingAction(false));
}

export default function* subDeals() {
    yield takeLatest(WASHOUT_ASSOC_SEARCH_START, list);
    yield takeLatest(ALL_WASHOUT_START, allList);
    yield takeLatest(COMPLETE_ASSOCIATE_LIST_START, completeAssociateList);
}
