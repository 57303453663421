import React from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { useStyles } from './styles';
import PropTypes from 'prop-types';
export const TablePrint = (props) => {
    const { dataTable, className } = props;
    const classes = useStyles();

    return (
        <TableContainer className="space-between-table">
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell size="small" align="center">
                            #
                        </TableCell>
                        {dataTable?.columns?.map((column, index) => (
                            <TableCell size="small" align="center" key={index}>
                                {column.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTable?.data?.map((rowdata, indexRow) => {
                        return (
                            <TableRow key={indexRow}>
                                <TableCell size="small" align="center">
                                    {indexRow + 1}
                                </TableCell>
                                {dataTable?.columns?.map((column, index) => {
                                    return (
                                        <TableCell
                                            size="small"
                                            className={
                                                index === 0 ? classes.alignLeft : classes.alignRight
                                            }
                                            align={index === 0 ? 'left' : 'right'}
                                            key={index}
                                        >
                                            {rowdata[column?.field]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export const TableSimplePrint = (props) => {
    const { dataTable, name, dmsCode } = props;
    let rowTotal, rowSubTotalPlusAdj, rowSubTotal;
    if (dmsCode !== '1557' && dmsCode !== '2267' && dmsCode !== '8104' && dmsCode !== 0) {
        delete dataTable['Special Adjustment'];
    }
    rowTotal = Object.keys(dataTable).length - 1;
    rowSubTotalPlusAdj = rowTotal - 2;
    rowSubTotal = rowTotal - 5;
    if (Object.keys(dataTable).length === 8) {
        rowTotal = Object.keys(dataTable).length - 1;
        rowSubTotalPlusAdj = rowTotal - 2;
        rowSubTotal = rowTotal - 5;
    } else {
        rowTotal = Object.keys(dataTable).length - 1;
        rowSubTotalPlusAdj = rowTotal - 2;
        if (dmsCode === '1557' || dmsCode === '2267'|| dmsCode === '8104') {
            rowSubTotal = rowTotal - 5;
        } else {
            rowSubTotal = rowTotal - 4;
        }
    }

    return (
        <TableContainer>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell size="small" align="center">
                            Description
                        </TableCell>
                        <TableCell size="small" align="center">
                            Value
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {name === 'summary'
                        ? Object.keys(dataTable).map((row, indexRow) => {
                              return (
                                  <TableRow key={indexRow}>
                                      <TableCell size="medium" align="center" style={{wordBreak:'break-all'}}>
                                          <div style={{ marginLeft: '70px' }}>{row}</div>
                                      </TableCell>
                                      <TableCell size="small" align="right" style={{wordBreak:'break-all'}}>
                                          {dataTable[row]}
                                      </TableCell>
                                  </TableRow>
                              );
                          })
                        : Object.keys(dataTable).map((row, indexRow) => {
                              if (
                                  indexRow === rowTotal ||
                                  indexRow === rowSubTotalPlusAdj ||
                                  indexRow === rowSubTotal
                              ) {
                                  return (
                                      <TableRow key={indexRow}>
                                          <TableCell
                                              size="small"
                                              align="center"
                                              style={{ fontWeight: 'bold' }}
                                          >
                                              {row}
                                          </TableCell>
                                          <TableCell
                                              size="small"
                                              align="right"
                                              style={{ fontWeight: 'bold' }}
                                          >
                                              {dataTable[row]}
                                          </TableCell>
                                      </TableRow>
                                  );
                              }
                              return (
                                  <TableRow key={indexRow}>
                                      <TableCell size="small" align="center">
                                          {row}
                                      </TableCell>
                                      <TableCell size="small" align="right">
                                          {dataTable[row]}
                                      </TableCell>
                                  </TableRow>
                              );
                          })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TableSimplePrint.propTypes = {
    dataTable: PropTypes.object,
    name: PropTypes.string,
    dmsCode: PropTypes.string,
};
TableSimplePrint.defaultProps = {
    dataTable: {},
    name: '',
    dmsCode: 0,
};
