import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompleteWashoutSheet, washoutSearch } from '../../../../redux/actions/washoutAction';
import { onSetIntialStateSubdealAction } from '../../../../redux/actions/subdealApiAction';
import { associateListByType } from '../../../../redux/actions/associateAction';
import { isLoadingAction } from '../../../../redux/actions/utilitiesAction';
import { isLoading } from '../../../../redux/selectors';
import {
    dinamicTableSelector,
    listNameCodeAssociateSelector,
    listResponseSelector,
    summaryWashoutSelector,
    respondeBonusSelector,
    payrollWashoutSelector,
} from '../../../../redux/selectors';
import View from './view';

export default ({ typeAssociate, handleChangeRadio }) => {
    const refDivPrint = useRef();
    const dispatch = useDispatch();
    const completeAssociateList = useSelector((state) =>
        dinamicTableSelector(state, 'washoutReducer', 'completeAssociateList')
    );
    const dataTableDeals = useSelector((state) =>
        dinamicTableSelector(state, 'washoutReducer', 'dinamicTableConf')
    );

    const dataTableDeals2 = useSelector((state) =>
        dinamicTableSelector(state, 'washoutReducer', 'dinamicTableConf2')
    );

    const dataTableDealsNew = useSelector((state) =>
        dinamicTableSelector(state, 'washoutReducer', 'dinamicTableConfNew')
    );

    const dataTableDealsUsed = useSelector((state) =>
        dinamicTableSelector(state, 'washoutReducer', 'dinamicTableConfUsed')
    );

    const associate = useSelector((state) => listNameCodeAssociateSelector(state));
    const listAsociate = useSelector((state) => listResponseSelector(state, 'associateReducer'));
    const bonus = useSelector((state) => respondeBonusSelector(state));
    const summary = useSelector((state) => summaryWashoutSelector(state));
    const payroll = useSelector((state) => payrollWashoutSelector(state));
    const dataTableAdjustmentTemp = useSelector((state) =>
        dinamicTableSelector(state, 'adjustmentReducer', 'associateWashout')
    );

    const [openDilogPrint, setOpenDilogPrint] = useState(false);

    const [dataSearchDeals, setDataSearchDeals] = useState({
        dms: '',
        month: '',
        year: '',
        number: '',
    });
    const [activePrint, setActivePrint] = useState(true);
    const [activeSearch, setActiveSearch] = useState(true);
    const [clearAutoComplete, setClearAutoComplete] = useState(false);
    const [dmsData, setDmsData] = useState({
        code: '',
        type: '',
        name: '',
    });

    const [isPrintLessDetails, setIsPrintLessDetails] = useState(false);

    const printLessDetailsHandler = useCallback(() => {
        onOpenDialogPrint();
        setIsPrintLessDetails(true);
    }, []);

    useEffect(() => {
        for (const array of listAsociate) {
            let dataAssociate = array.filter((obj) => obj.dms_number == dataSearchDeals.dms);
            if (dataAssociate.length > 0) {
                setDmsData({
                    code: dataSearchDeals.dms,
                    type: dataAssociate[0].type,
                    name: dataAssociate[0].name,
                });
                break;
            }
        }
    }, [dataSearchDeals.dms]);

    useEffect(() => {
        if (completeAssociateList?.length > 0) {
            setActivePrint(false);
        } else {
            setActivePrint(true);
        }
    }, [completeAssociateList]);
    useEffect(() => {
        if (dataSearchDeals.month && dataSearchDeals.year) {
            setActiveSearch(false);
        }
    }, [dataSearchDeals]);

    useEffect(() => {
        if (!listAsociate.length) {
            dispatch(associateListByType());
        }
    }, []);

    const dataTableAdjustment = () => {
        let data = { columns: [], data: [] };
        for (let i = 0; i < 5; i++) {
            data.columns.push(dataTableAdjustmentTemp.columns[i]);
        }
        for (const row in dataTableAdjustmentTemp.data) {
            data.data.push(dataTableAdjustmentTemp.data[row]);
        }
        data.columns.splice(0, 1);
        return data;
    };
    const onChangeDataSearch = (e) => {
        setDataSearchDeals({
            ...dataSearchDeals,
            [e.target.name]: e.target.value,
        });
    };
    const onChangeDataSearchAutocomplete = (value) => {
        if (value) {
            setDataSearchDeals({
                ...dataSearchDeals,
                dms: value.code,
            });
        }
    };
    const onCLickSearch = () => {
        let date = `${dataSearchDeals.month}${dataSearchDeals.year}`;

        dispatch(isLoadingAction(true));
        setActivePrint(true);
        dispatch(getCompleteWashoutSheet({ date, typeAssociate }));
        dispatch(onSetIntialStateSubdealAction());
    };

    const onOpenDialogPrint = () => {
        setOpenDilogPrint(true);
    };
    const onCloseDialogPrint = () => {
        setOpenDilogPrint(false);
        setIsPrintLessDetails(false);
    };

    const changeRadioTypeHandler = (event) => {
        setClearAutoComplete(!clearAutoComplete);
        handleChangeRadio(event.target.value);
    };

    return (
        <View
            dataSearchDeals={dataSearchDeals}
            onChangeDataSearch={onChangeDataSearch}
            onCLickSearch={onCLickSearch}
            openDilogPrint={openDilogPrint}
            onOpenDialogPrint={onOpenDialogPrint}
            onCloseDialogPrint={onCloseDialogPrint}
            refDivPrint={refDivPrint}
            activePrint={activePrint}
            listAssociate={associate}
            listAsociate={listAsociate}
            typeAssociate={typeAssociate}
            handleChangeRadio={changeRadioTypeHandler}
            activeSearch={activeSearch}
            onChangeDataSearchAutocomplete={onChangeDataSearchAutocomplete}
            clearAutoComplete={clearAutoComplete}
            dmsData={dmsData}
            bonus={bonus}
            summary={summary}
            payroll={payroll}
            dataTableDeals={dataTableDeals}
            dataTableAdjustment={dataTableAdjustment}
            dataTableDealsNew={dataTableDealsNew}
            dataTableDealsUsed={dataTableDealsUsed}
            dataTableDeals2={dataTableDeals2}
            dataTableAdjustmentTemp={dataTableAdjustmentTemp}
            isPrintLessDetails={isPrintLessDetails}
            printLessDetailsHandler={printLessDetailsHandler}
            completeAssociateList={completeAssociateList}
        />
    );
};
